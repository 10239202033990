<template>
  <div class="row">
    <div
      class="col-sm-4 project-card"
      v-for="(items, i) in this.projects"
      :key="i"
    >
      <div class="card">
        <div class="body-card" style="padding: 10px 20px">
          <span style="font-size: 16px; font-weight: bold">{{
            items.projectTitle
          }}</span>

          <br />

          <span class="text-muted" style="font-size: 14px; font-weight: bold"
            >{{ this.$t("totalKeys") }}: {{ items.totalProjectKeys }}</span
          >

          <div class="progress" style="margin: 15px 10px; height: 15px">
            <div
              class="progress-bar bg-success"
              :style="`width: ${items.projectPercent}%`"
              role="progressbar"
              aria-valuemin="0"
              aria-valuemax="100"
            >
              {{ items.projectPercent }}%
            </div>
          </div>

          <hr />

          <table style="width: 100%">
            <thead>
              <th style="width: 70%">{{ this.$t("lang") }}</th>
              <th style="color: green; width: 10%">П.</th>
              <th style="color: red; width: 10%">Неп.</th>
              <th style="color: green; width: 10%">%</th>
            </thead>
            <tbody>
              <tr
                v-for="(item, k) in items.projectLangs"
                :key="i"
                style="font-size: 0.85rem"
              >
                <td>
                  {{ this.langs.find((el) => el.value == k)?.title }} ({{ k }})
                </td>
                <td style="color: green">
                  {{ item.percent != 100 ? item.translatedKeys : "" }}
                </td>
                <td style="color: red">
                  {{ item.percent != 100 ? item.emptyKeys : "" }}
                </td>
                <td style="color: green">{{ item.percent }}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { TranslatorLocalise } from "@/API_Dash";

let apiServe = new TranslatorLocalise();

export default {
  data() {
    return {
      projects: [],
      langs: [
        {
          value: "fr",
          title: "Français",
        },
        {
          value: "en",
          title: "English",
        },
        {
          value: "ru",
          title: "Русский",
        },
        {
          value: "de",
          title: "Deutsch",
        },
        {
          value: "es",
          title: "Español",
        },
        {
          value: "pl",
          title: "Polski",
        },
        {
          value: "uk",
          title: "Українська",
        },
        {
          value: "it",
          title: "Italiano",
        },
        {
          value: "he",
          title: "עִבְרִית",
        },
        {
          value: "tr",
          title: "Türkçe",
        },
        {
          value: "et",
          title: "eesti keel",
        },
        {
          value: "be",
          title: "Беларуская мова",
        },
        {
          value: "ar",
          title: "العربية",
        },
        {
          value: "fi",
          title: "suomi",
        },
        {
          value: "lt",
          title: "lietuvių kalba",
        },
        {
          value: "tg",
          title: "Забони тоҷикӣ",
        },
        {
          value: "pt",
          title: "Português",
        },
        {
          value: "ka",
          title: "Georgian (Georgia)",
        },
        {
          value: "cn",
          title: "Chinese Simplified",
        },
        {
          value: "el",
          title: "Greek",
        },
        {
          value: "km",
          title: "Khmer (Cambodia)",
        },
        {
          value: "lv",
          title: "Latvian",
        },
        {
          value: "ro",
          title: "Romanian",
        },
        {
          value: "th",
          title: "ภาษาไทย (Thai)",
        },
      ],
    };
  },
  created() {
    this.getdata(); // отримуємо дані
  },
  methods: {
    getdata() {
      apiServe.getProjectsStats().then((result) => {
        if (result.status === "done") {
          this.projects = result.data;
        }
      });
    },
  },
};
</script>
